import { template as template_3db46627b18e465094821c3a034e3489 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const OpLikesCell = template_3db46627b18e465094821c3a034e3489(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="op_likes"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="likes"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default OpLikesCell;
