import { template as template_a9cb24eb378e4ff0b491a1136ab0013d } from "@ember/template-compiler";
const SidebarSectionMessage = template_a9cb24eb378e4ff0b491a1136ab0013d(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
