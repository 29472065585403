import { template as template_d569cc952a734206b49e50c59d63501d } from "@ember/template-compiler";
const FKLabel = template_d569cc952a734206b49e50c59d63501d(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
