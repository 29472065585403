import { template as template_dfbeab22355049fc8dc5c87c74f06cac } from "@ember/template-compiler";
const FKText = template_dfbeab22355049fc8dc5c87c74f06cac(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
