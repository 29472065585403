import { template as template_40fbc810dfd24ff8a7af89f30d87a37d } from "@ember/template-compiler";
import DButton from "discourse/components/d-button";
const BackButton = template_40fbc810dfd24ff8a7af89f30d87a37d(`
  <DButton
    @action={{@onGoBack}}
    @label="topic.timeline.back"
    @title="topic.timeline.back_description"
    class="btn-primary btn-small back-button"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default BackButton;
