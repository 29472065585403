import { template as template_d1da6f80ae5a4ff4bec9aafce01d92a3 } from "@ember/template-compiler";
const WelcomeHeader = template_d1da6f80ae5a4ff4bec9aafce01d92a3(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
